<script>
// nova branch
import Multiselect from "vue-multiselect";
import { required } from "vuelidate/lib/validators";
//import { http } from '@/helpers/easyindustriaapi/config';
export default {
  props: {
    empresa: { type: Object, required: true },
  },
  components: {
    Multiselect,
  },
  data() {
    return {
      titleBody: 'Detalhes de cheque',
     
      bancos:[],

      item: [
        'item1',
        'item2',
        'item3',
      ],
      submitted : false,
      cheque: {
        contacorrente_id: null,
        cheque_num: null,
        cheque_emitente: null,
        cheque_valor: null,
        cheque_dtemissao: null,
        cheque_dtvencimento: null,
        cheque_dttitulo: null,
        cheque_status: null,
        cheques_situacao: null,
        cheques_p_t: null,
        cheques_tipo: null,
        cheques_contacorrente: null,
        cheques_cnpjcpf: null,
        cheques_agencia: null,
        banco_id: null,
        empresa_id: null,
        user_id: null,

      },
      cheque2: {
        contacorrente_id: 1,
        cheque_num: '1234',
        cheque_emitente: '123s',
        cheque_valor: 1.00,
        cheque_dtemissao: '2023-06-30T08:43',
        cheque_dtvencimento: '2023-06-30T08:43',
        cheque_dttitulo: '2023-06-30T08:43',
        cheque_status: 1,
        cheques_situacao: 1,
        cheques_p_t: 'as',
        cheques_tipo: 'Cheque',
        cheques_contacorrente: 'contrante',
        cheques_cnpjcpf: 'cnpj',
        cheques_agencia: 'asd',
        banco_id: 1,
      }
    }
  }, 
  validations() {
    return {
      cheque2: {
        contacorrente_id: {required},
        cheque_num:{required},
        cheque_emitente:{required},
        cheque_valor: {required},
        cheque_dtemissao:{required},
        cheque_dtvencimento:{required},
        cheque_dttitulo:{required},
        cheque_status:{required},
        cheques_situacao:{required},
        cheques_p_t: {required},
        cheques_tipo:{required},
        cheques_contacorrente:{required},
        cheques_cnpjcpf: {required},
        cheques_agencia: {required},
        banco_id:{required},
      }
    }
  },
  created() { },
  mounted() {
    this.$emit('newTitle', this.titleBody);
   // this.getBancos();
  },
  methods: {
    makeToast(variant = null, message = 'Error generic') {
      this.counter++;
      this.$bvToast.toast(message, {
        title: `Notificação`,
        toaster: 'b-toaster-bottom-right',
        variant: variant,
        solid: true,
        appendToast: true
      });
    },
  // async  getBancos(){

  //   try {
  //       let response = await http.get('/banco?empresa_id=' + this.currentEmpresa.id);
        
  //       if (response.status === 200) {
  //         this.bancos = response.data
  //         console.log(this.bancos);
        
  //         this.offLoader();
  //       }
  //     } catch (error) {
  //       let messageErro = error.response.data;

  //       if(messageErro){
  //           console.log(messageErro);
  //       }
  //     }

  //   },
      // eslint-disable-next-line no-unused-vars
      formSubmit(e) {
      this.submitted = true;
      // stop here if form is invalid
      this.$v.$touch();

      if (this.$v.cheque2.$error) {
        this.makeToast('danger', 'Erro de validação!');
      } else {
        this.setPost();
      }
    },
    setPost() {
      
      this.$emit('doPost', this.cheque2);
    },
  },
}
</script>

<template>
  <div class="card">
    <div class="card">
      <div class="card-body">

        <form id="form-centro-resultado" role="form" class="form-horizontal">
          <b-card no-body class="mb-1">
            <b-card-body>
              <div class="form-row form-group" role="group">
                <div class="col-sm-12 col-md-3">
                  <div class="form-group">
                    <label for="centro_resultado_id" class="col-form-label">Numero</label>
                    <div class="col">
                      <input v-model="cheque2.cheque_num" class="form-control text-left" type="text"
                        placeholder="Gerado pelo sistema" id="centro_resultado_id"
                        :class="{ 'is-invalid': submitted && $v.cheque2.cheque_num.$error }" ref="cheque_num">
                        <div v-if="submitted && $v.cheque2.cheque_num.$error" class="invalid-feedback">
                        <span v-if="!$v.cheque2.cheque_num.required"> {{ $t('mesages.validation.required') }}</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-sm-12 col-md-3">
                  <div class="form-group">
                    <label for="centro_resultado_id" class="col-form-label">Agencia</label>
                    <div class="col">
                      <input v-model="cheque2.cheques_agencia" class="form-control text-left" type="text"
                        placeholder="Gerado pelo sistema" id="centro_resultado_id">
                    </div>
                  </div>
                </div>
                <div class="col-sm-12 col-md-3">
                  <div class="form-group">
                    <label for="centro_resultado_id" class="col-form-label">Conta Corrente</label>
                    <div class="col">
                      <input v-model="cheque2.contacorrente_id" class="form-control text-left" type="text"
                        placeholder="Gerado pelo sistema" id="centro_resultado_id">
                    </div>
                  </div>
                </div>

              </div>
              <div class="form-row form-group" role="group">
                <div class="col-sm-12 col-md-3">
                  <div class="form-group">
                    <label for="centro_resultado_id" class="col-form-label">Nome do Emitente</label>
                    <div class="col">
                      <input v-model="cheque2.cheque_emitente" class="form-control text-left" type="text"
                        placeholder="Gerado pelo sistema" id="centro_resultado_id"
                        :class="{ 'is-invalid': submitted && $v.cheque2.cheque_emitente.$error }" ref="cheque_emitente">

                        <div v-if="submitted && $v.cheque2.cheque_emitente.$error" class="invalid-feedback">
                        <span v-if="!$v.cheque2.cheque_emitente.required"> {{ $t('mesages.validation.required') }}</span>
                      </div>
                        
                    </div>
                  </div>
                </div>
                <div class="col-sm-12 col-md-3">
                  <div class="form-group">
                    <label for="centro_resultado_id" class="col-form-label">Banco</label>
                    <div class="col">
                      <multiselect v-model="cheque2.banco_id" :options="item"
                      :class="{ 'is-invalid': submitted && $v.cheque2.banco_id.$error }" ref="banco_id">

                      </multiselect>
                      <div v-if="submitted && $v.cheque2.banco_id.$error" class="invalid-feedback">
                        <span v-if="!$v.cheque2.banco_id.required"> {{ $t('mesages.validation.required') }}</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-sm-12 col-md-3">
                  <div class="form-group">
                    <label for="centro_resultado_id" class="col-form-label">Vencimento</label>
                    <div class="col">
                      <input v-model="cheque2.cheque_dtvencimento" id="searchCompras-fromData" type="datetime-local"
                        class="form-control" placeholder="dd/mm/aaaa hh:mm" />
                    </div>
                  </div>
                </div>
                <div class="col-sm-12 col-md-3">
                  <div class="form-group">
                    <label for="centro_resultado_id" class="col-form-label">Valor</label>
                    <div class="col">
                      <input v-model="cheque2.cheque_valor" class="form-control text-left" type="text"
                        placeholder="Gerado pelo sistema" id="centro_resultado_id">
                    </div>
                  </div>
                </div>

              </div>
              <div class="form-row form-group" role="group">
                <div class="col-sm-12 col-md-3">
                  <div class="form-group">
                    <label for="centro_resultado_id" class="col-form-label">Data Titulo</label>
                    <div class="col">
                      <input v-model="cheque2.cheque_dttitulo" id="searchCompras-fromData" type="datetime-local"
                        class="form-control" placeholder="dd/mm/aaaa hh:mm" />
                    </div>
                  </div>
                </div>
                <div class="col-sm-12 col-md-4">
                  <div class="form-group">
                    <label for="centro_resultado_id" class="col-form-label">Data Emissão</label>
                    <div class="col">
                      <input v-model="cheque2.cheque_dtemissao" id="searchCompras-fromData" type="datetime-local"
                        class="form-control" placeholder="dd/mm/aaaa hh:mm" />
                    </div>
                  </div>
                </div>
                <div class="col-sm-12 col-md-2">
                  <div class="form-group">
                    <label for="centro_resultado_id" class="col-form-label">Tipo de cheque</label>
                    <div class="col">
                      <b-form-checkbox v-model="cheque2.cheques_tipo" id="produto-ativo1" name="ativo" value="-1">
                        Proprio
                      </b-form-checkbox>
                    </div>
                  </div>
                </div>
                <div class="col-sm-12 col-md-2">
                  <div class="form-group">
                    <label for="centro_resultado_id" class="col-form-label">&nbsp;</label>
                    <div class="col">
                      <b-form-checkbox v-model="cheque2.cheques_tipo" id="produto-ativo1" name="ativo" value="-1">
                        Terceiro
                      </b-form-checkbox>
                    </div>
                  </div>
                </div>
                <div class="col-sm-12 col-md-3">
                  <div class="form-group">
                    <label for="centro_resultado_id" class="col-form-label">Chque P-T</label>
                    <div class="col">
                      <input minlength="2" maxlength="2" v-model="cheque2.cheques_p_t" class="form-control text-left" type="text"
                        placeholder="Gerado pelo sistema" id="centro_resultado_id"
                        :class="{ 'is-invalid': submitted && $v.cheque2.cheques_p_t.$error }" ref="cheques_p_t">
                        <div v-if="submitted && $v.cheque2.cheques_p_t.$error" class="invalid-feedback">
                        <span v-if="!$v.cheque2.cheques_p_t.required"> {{ $t('mesages.validation.required') }}</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-sm-12 col-md-3">
                  <div class="form-group">
                    <label for="centro_resultado_id" class="col-form-label">Chque Conta Corrente</label>
                    <div class="col">
                      <input v-model="cheque2.cheques_contacorrente" class="form-control text-left" type="text"
                        placeholder="Gerado pelo sistema" id="centro_resultado_id">
                    </div>
                  </div>
                </div>
                <div class="col-sm-12 col-md-3">
                  <div class="form-group">
                    <label for="centro_resultado_id" class="col-form-label">Chque CNPJ/CPF</label>
                    <div class="col">
                      <input v-model="cheque2.cheques_cnpjcpf" class="form-control text-left" type="text"
                        placeholder="Gerado pelo sistema" id="centro_resultado_id">
                    </div>
                  </div>
                </div>
              </div>
            </b-card-body>
          </b-card>
        </form>
      </div>
    </div>
    <div class="accordion" role="tablist">
      <form role="form" class="form-horizontal">
        <b-card no-body class="mb-1">
          <b-card-header header-tag="header" class="p-1" role="tab">
            <b-button block v-b-toggle.pedido-dados-gerais variant="light">Situação - (Clique para Visualizar)</b-button>
          </b-card-header>
          <!-- visible -- coloca esse comando no b-colapse se quiser que já fique aberto -->
          <b-collapse id="pedido-dados-gerais" accordion="pedido-accordion" role="tabpanel">
            <b-card-body>
              <div class="form-row form-group" role="group">
                <div class="col-sm-12 col-md-2">
                  <div class="form-group">
                    <label for="produto_id" class="col-form-label">&nbsp;</label>
                    <div class="col">
                      <b-form-checkbox v-model="cheque2.cheques_situacao" id="produto-ativo1" name="ativo" value="-1">
                        001-Custodia de Empresa
                      </b-form-checkbox>
                    </div>
                  </div>
                </div>
                <div class="col-sm-12 col-md-2">
                  <div class="form-group">
                    <label for="produto_id" class="col-form-label">&nbsp;</label>
                    <div class="col">
                      <b-form-checkbox v-model="cheque2.cheques_situacao" id="produto-ativo2" name="ativo" value="-2"
                        unchecked-value="0">
                        003-Compensado
                      </b-form-checkbox>
                    </div>
                  </div>
                </div>
                <div class="col-sm-12 col-md-2">
                  <div class="form-group">
                    <label for="produto_id" class="col-form-label">&nbsp;</label>
                    <div class="col">
                      <b-form-checkbox v-model="cheque2.cheques_situacao" id="produto-ativo3" name="ativo" value="-3"
                        unchecked-value="0">
                        005-cancelado
                      </b-form-checkbox>
                    </div>
                  </div>
                </div>
                <div class="col-sm-12 col-md-2">
                  <div class="form-group">
                    <label for="produto_id" class="col-form-label">&nbsp;</label>
                    <div class="col">
                      <b-form-checkbox v-model="cheque2.cheques_situacao" id="produto-ativo4" name="ativo" value="-4"
                        unchecked-value="0">
                        007-Restituido
                      </b-form-checkbox>
                    </div>
                  </div>
                </div>
                <div class="col-sm-12 col-md-2">
                  <div class="form-group">
                    <label for="produto_id" class="col-form-label">&nbsp;</label>
                    <div class="col">
                      <b-form-checkbox v-model="cheque2.cheques_situacao" id="produto-ativo5" name="ativo" value="-5"
                        unchecked-value="0">
                        009-Custodia Bancaria
                      </b-form-checkbox>
                    </div>
                  </div>
                </div>
              </div>
              <div class="form-row form-group" role="group">
                <div class="col-sm-12 col-md-2">
                  <div class="form-group">
                    <label for="produto_id" class="col-form-label">&nbsp;</label>
                    <div class="col">
                      <b-form-checkbox v-model="cheque2.cheques_situacao" id="produto-ativo6" name="ativo" value="-6"
                        unchecked-value="0">
                        002-Depositado
                      </b-form-checkbox>
                    </div>
                  </div>
                </div>
                <div class="col-sm-12 col-md-2">
                  <div class="form-group">
                    <label for="produto_id" class="col-form-label">&nbsp;</label>
                    <div class="col">
                      <b-form-checkbox v-model="cheque2.cheques_situacao" id="produto-ativo7" name="ativo" value="-7"
                        unchecked-value="0">
                        004-Devolvido
                      </b-form-checkbox>
                    </div>
                  </div>
                </div>
                <div class="col-sm-12 col-md-2">
                  <div class="form-group">
                    <label for="produto_id" class="col-form-label">&nbsp;</label>
                    <div class="col">
                      <b-form-checkbox v-model="cheque2.cheques_situacao" id="produto-ativo8" name="ativo" value="-8"
                        unchecked-value="0">
                        006-Reapresentado
                      </b-form-checkbox>
                    </div>
                  </div>
                </div>
                <div class="col-sm-12 col-md-2">
                  <div class="form-group">
                    <label for="produto_id" class="col-form-label">&nbsp;</label>
                    <div class="col">
                      <b-form-checkbox v-model="cheque2.cheques_situacao" id="produto-ativo9" name="ativo" value="-9"
                        unchecked-value="0">
                        008-Negociado
                      </b-form-checkbox>
                    </div>
                  </div>
                </div>
                <div class="col-sm-12 col-md-2">
                  <div class="form-group">
                    <label for="produto_id" class="col-form-label">&nbsp;</label>
                    <div class="col">
                      <b-form-checkbox v-model="cheque2.cheques_situacao" id="produto-ativo10" name="ativo" value="-10"
                        unchecked-value="0">
                        010-Inegociavel
                      </b-form-checkbox>
                    </div>
                  </div>
                </div>
              </div>
            </b-card-body>
          </b-collapse>

          <!-- separa -->
          <!-- visible -- coloca esse comando no b-colapse se quiser que já fique aberto -->
          <b-card-header header-tag="header" class="p-1" role="tab">
            <b-button block v-b-toggle.valores-informados variant="light">Outras Informações - (Clique para
              Visualizar)</b-button>
          </b-card-header>
          <b-collapse id="valores-informados" accordion="valores-informados" role="tabpanel">
            <b-card-body>
              <div class="form-row form-group" role="group">
                
                <div class="col-sm-12 col-md-3" id="desc_ID">
                  <div class="form-group">
                    <label for="cartao_in_id" class="col-form-label">Codigo do Usuarios</label>
                    <div class="col">
                      <input v-model="cheque2.cod_user" id="cartao_in_id" type="text" class="form-control text-left"
                        placeholder="digite">
                    </div>
                  </div>
                </div>
                <div class="col-sm-12 col-md-3" id="desc_ID">
                  <div class="form-group">
                    <label for="cheque_in_id" class="col-form-label">Ativo</label>
                    <div class="col">
                      <b-form-checkbox v-model="cheque2.cheque_status" id="produto-ativo11" name="ativo" 
                        :class="{ 'is-invalid': submitted && $v.cheque2.cheque_status.$error }" ref="cheque_status">
                      </b-form-checkbox>
                      <div v-if="submitted && $v.cheque2.cheque_status.$error" class="invalid-feedback">
                        <span v-if="!$v.cheque2.cheque_status.required"> {{ $t('mesages.validation.required') }}</span>
                      </div>
                    </div>
                  </div>
                </div>

              </div>
              <div class="form-row form-group" role="group" id="espaco">

                <!-- vou colocar algo aqui  -->

              </div>
            </b-card-body>
          </b-collapse>
          <!-- Menus de Impostos-->


          <!--- ------------ -->
        </b-card>
        <!-- engloba  todos-->
      </form>
      <!-- form engloba todos-->
    </div>
    <!--Engloba todos -->

    <div class="card-body" id="top">
      <div class="col-md-12 mt-10">
        <button class="btn btn-light" id="butt" @click="formSubmit">
          Gravar
        </button>

      </div>
    </div>
  </div>
</template>


<style scoped>
/* .col{
  padding: 10px;
} */
#butt {
  padding: 10px;
  margin-left: 2%;
}

#top {
  margin-top: 50px;
}
</style>